$ = jQuery;
$( document ).ready( setSpacerWidth );
$( window ).resize( setSpacerWidth );

function setSpacerWidth() {
    var win = $( window ).width();
    var nav = $( '.navbar' ).width();
    var spacer = (win - nav + 4) / 2;
    $( '.spacer' ).width(spacer);

    // Set spacer height
    var navHeight = $( '.navbar' ).height();
    $( '.spacer' ).height(navHeight);
}

