// Source: https://developers.google.com/maps/documentation/javascript/geocoding#GetStarted
// Source: https://developers.google.com/maps/documentation/javascript/markers
// Source: https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes
var geocoder;
var map;
var article;
var address;
var tooltip;

$ = jQuery;

function initMap() {
    geocoder = new google.maps.Geocoder();

	// Get URL params
	// Source: http://www.technicaloverload.com/get-value-url-parameters-using-javascript/
	function getParameter(theParameter) { 
		var params = window.location.search.substr(1).split('&');
		
		for (var i = 0; i < params.length; i++) {
			var p=params[i].split('=');
			if (p[0] == theParameter) {
			return decodeURIComponent(p[1]);
			}
		}
		return false;
	}
	var lng_url = getParameter('lng');
	var lat_url = getParameter('lat');
	var coordinates = [];
	
	if ( lng_url ) {
		coordinates['lng'] = lng_url;
	} else {
		coordinates['lng'] = -79.3849; // By default it is Toronto
	}

	if ( lat_url ) {
		coordinates['lat'] = lat_url;
	} else {
		coordinates['lat'] = 43.6529; // By default it is Toronto
	}

	// **** start of comment out
	// The code below doesn't work in IE
	// Get Lat-Lng from GET query. We show map by postal code even if there is no dealers on this address
	// Source: https://www.codexworld.com/how-to/get-query-string-from-url-javascript/
	// var urlParams = new URLSearchParams(location.search);

	// var coordinates = [];
	
	// if ( urlParams.has('lng') ) {
	// 	coordinates['lng'] = urlParams.get('lng');
	// } else {
	// 	coordinates['lng'] = -79.3849; // By default it is Toronto
	// }

	// if ( urlParams.has('lat') ) {
	// 	coordinates['lat'] = urlParams.get('lat');
	// } else {
	// 	coordinates['lat'] = 43.6529; // By default it is Toronto
	// }
	// ***** end of comment out

	// Styles for map
	// Source: https://snazzymaps.com/style/8097/wy
	var style_args = [
		{
			"featureType": "all",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"weight": "2.00"
				}
			]
		},
		{
			"featureType": "all",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"color": "#9c9c9c"
				}
			]
		},
		{
			"featureType": "all",
			"elementType": "labels.text",
			"stylers": [
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "all",
			"stylers": [
				{
					"color": "#f2f2f2"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "landscape.man_made",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "all",
			"stylers": [
				{
					"saturation": -100
				},
				{
					"lightness": 45
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#eeeeee"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#7b7b7b"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "simplified"
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "all",
			"stylers": [
				{
					"color": "#46bcec"
				},
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#c8d7d4"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#070707"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		}
	]

    var latlng = new google.maps.LatLng(coordinates['lat'], coordinates['lng']);
    var mapOptions = {
      zoom: 8,
      center: latlng,
	  styles: style_args
    }
    map = new google.maps.Map(document.getElementById('map'), mapOptions);

    // Get all <div> with dealers, place markers
    var articles = document.getElementsByClassName('dealer');
    if ( articles ) {
        for (var i = 0; i < Math.min(5, articles.length); i++) { // limit to 5 markers
            article = articles[i];
            var addrArr = [article.dataset.street, article.dataset.city, article.dataset.province, article.dataset.zip];
            var infoWindow = '<div class="m-0 py-4 px-5 infowin"><div class="mb-3">' + article.dataset.name + '</div>' + '<div><span class="fas fa-phone"></span> ' + article.dataset.phone + '</div></div>';
            var tooltip = article.dataset.name;
            address = addrArr.join(', ');
            codeAddress( address, tooltip, infoWindow );
        }
    }    
}

function codeAddress(address, tooltip, infoWindow) {
    geocoder.geocode( { 'address': address}, function(results, status) {
      if (status == 'OK') {
            var infowindow = new google.maps.InfoWindow({
                content: infoWindow
            });
        map.setCenter(results[0].geometry.location);
        var marker = new google.maps.Marker({
            map: map,
            position: results[0].geometry.location,
            title: tooltip,
            animation: google.maps.Animation.DROP
        });
        marker.addListener('click', function() {
          infowindow.open(map, marker);
        });
      } else {
        console.log('Geocode was not successful for the following reason: ' + status);
      }
    });
}

// Find Lat and Lng after user click 'Browse' and before page reloads
$('#filterForm').submit( function( event ) {
	event.preventDefault();

	// Search by postal code if it not empty, otherwise search by city if it is not empty
	var cityInput = document.getElementById('cityInput').value;
	var postalInput = document.getElementById('postalInput').value;

	if ( '' !== cityInput ) {
		find_coordinates_city( function( data ) {
			if ( 'OK' === data.Status ) {
				document.getElementById("locLng").value = data.Longitude;
				document.getElementById("locLat").value = data.Latitude;
				
				$('#filterForm').unbind("submit").submit();
			}
		});
	} else if ( '' !== postalInput ) { 
		find_coordinates( function( data ) {
			if ( 'OK' === data.Status ) {
				document.getElementById("locLng").value = data.Longitude;
				document.getElementById("locLat").value = data.Latitude;
				
				$('#filterForm').unbind("submit").submit();
			}
		});
	} else {
		$('#filterForm').unbind("submit").submit();
	}
    
});

// Find coordinates by postal code
// Source: https://stackoverflow.com/questions/5585957/get-latlng-from-zip-code-google-maps-api
// Source: https://developers.google.com/maps/documentation/javascript/examples/geocoding-simple?csw=1
function find_coordinates( callback ) {
    var postalcode = document.getElementById("postalInput").value;
    if ( postalcode !== '' ) {
        var lat = '';
        var lng = '';

        geocoder.geocode( 
          {
            'componentRestrictions': {
              'postalCode': postalcode,
			  
              }
          },
          function( results, status ) {
            if ( status == google.maps.GeocoderStatus.OK ) {
                lat = results[0].geometry.location.lat();
                lng = results[0].geometry.location.lng();
                callback({ Status: "OK", Latitude: lat, Longitude: lng });
            } else {
                console.log("Geocode was not successful for the following reason: " + status);
            }
        });
    }
}

function find_coordinates_city( callback ) {
    var cityName = document.getElementById("cityInput").value;
    if ( cityName !== '' ) {
        var lat = '';
        var lng = '';

        geocoder.geocode( 
          {
            'componentRestrictions': {
              'locality': cityName,
			  'country': 'ca',
              }
          },
          function( results, status ) {
            if ( status == google.maps.GeocoderStatus.OK ) {
                lat = results[0].geometry.location.lat();
                lng = results[0].geometry.location.lng();
                callback({ Status: "OK", Latitude: lat, Longitude: lng });
            } else {
                console.log("Geocode was not successful for the following reason: " + status);
            }
        });
    }
}