$ = jQuery;
$(document).ready(function(){

	// Hide real select field
	//brandInpput
	//$('#brandInpput').hide();
	$('#filterform').submit(function(){
		// source: https://stackoverflow.com/questions/29882361/show-datalist-labels-but-submit-the-actual-value
		//var $answer = $('#brandIn').val();
		//console.log('Value of answer ' + $answer);
		//var $dataset = $("#brandInpput2 option[value='"+$answer+"']").attr('data-value');
		//console.log('Field with answer ' + $dataset);
		//var res = $('#brandInpput').val($dataset);
		//console.log('Value of #brandInpput ' + res);
		
		
		// document.getElementById("brandIn").value;
		//var value2send = document.querySelector("#answers option[value='"+shownVal+"']").dataset.value;

	});
});